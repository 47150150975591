import React from "react";
import BannerImage from "../../BannerImage/BannerImage";
import { useDatasources, useStoryblokSlug } from "../../../contexts/StoryblokContext/StoryblokContext";

interface BannerProps {}

const Banner: React.FC<BannerProps> = () => {
  const { activeLanguage } = useDatasources();
  const bannerStory = useStoryblokSlug("payment-summary-banner", activeLanguage);

  return (
    <div className="h-banner flex w-full flex-col">
      <div className="absolute w-full">
        {bannerStory?.content && (
          <BannerImage url={bannerStory.content.blocks[0].backgroundImage.filename} size="base" />
        )}
      </div>
    </div>
  );
};

export default Banner;
