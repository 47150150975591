import React from "react";
import { Icon, Text } from "@ilc-technology/luik";
import { AdditionalInfo } from "../ErrorHandling/AdditionalInfo";

interface InformationBoxProps {
  intent?: "default" | "warning" | "alert";
  title: string;
  content?: string;
  iconName: string;
  testid?: string;
  additionalInfo?: string;
}

const Surface: React.FC<InformationBoxProps> = ({
  title,
  content,
  iconName,
  intent = "default",
  testid = "information-box",
  additionalInfo,
}) => {
  const backgroundColorStyles = {
    default: "bg-default-light",
    warning: "bg-warning-light",
    alert: "bg-danger-lighter",
  };

  const iconColorStyles = {
    default: "text-primary-accessible",
    warning: "text-warning",
    alert: "text-alert",
  };

  return (
    <div>
      <div
        className={`shadow-light a-rounded a-gap-sm flex p-3 sm:p-6 ${backgroundColorStyles[intent]}`}
        data-testid={testid}
      >
        <div className="flex flex-col justify-center">
          <Icon iconName={iconName} size="lg" className={`${iconColorStyles[intent]}`} />
        </div>
        <div className="flex flex-col gap-[2px]">
          <Text variant="label-lg-bold">{title}</Text>
          {content && <Text variant="label-md">{content}</Text>}
        </div>
      </div>
      <AdditionalInfo additionalInfo={additionalInfo} />
    </div>
  );
};

export default Surface;
