import { ErrorCode, ErrorDetails, OptionalErrorDetails, ResponseWithFlowId, StringIndexableDictionary } from "../Types";
import { getRumClient } from "./AwsRumService";
const ErrorPrefix = "error_";
const ErrorTitleSuffix = "_title";
const ErrorMessageSuffix = "_message";

export function getErrorTitleToDisplay(error: ErrorDetails, labels?: StringIndexableDictionary<string>): string {
  return getDisplayValueForErrorCode(
    error.code,
    ErrorTitleSuffix,
    labels,
    error?.details?.fallbackTitle,
    "Something went wrong"
  );
}

export function getErrorMessageToDisplay(error: ErrorDetails, labels?: StringIndexableDictionary<string>): string {
  return getDisplayValueForErrorCode(error.code, ErrorMessageSuffix, labels, error?.details?.fallbackMessage);
}

function getDisplayValueForErrorCode(
  errorCode: ErrorCode,
  suffix: string,
  labels?: StringIndexableDictionary<string>,
  fallbackValue?: string,
  defaultValue: string = ""
): string {
  const errorKey = `${ErrorPrefix}${errorCode}${suffix}`;
  if (labels && labels[errorKey]) {
    return labels[errorKey];
  } else if (fallbackValue) {
    return fallbackValue;
  } else {
    return defaultValue;
  }
}

export function formattedEfFlowId(efFlowId: string) {
  return `Correlation id: ${efFlowId}`;
}

export function getErrorDetailsToDisplay(error: ErrorDetails): string {
  const result = [];
  error?.details?.additionalDetails && result.push(error.details.additionalDetails);
  error.efFlowId && result.push(formattedEfFlowId(error.efFlowId));
  result.push(`Code: ${error.code}`);
  error.responseStatusCode && result.push(`Server responded with ${error.responseStatusCode}`);

  return result.filter((item) => item !== null && item !== undefined).join(". ");
}

export function logError(error?: ErrorDetails): void {
  if (!error) return;
  console.error(JSON.stringify(error));
  const rumClient = getRumClient();
  rumClient?.recordError(new Error(JSON.stringify(error)));
}

export function convertToErrorDetails(error: any, code: ErrorCode, det?: OptionalErrorDetails): ErrorDetails {
  if (error instanceof ErrorDetails) {
    const details = error as ErrorDetails;
    details.code = code;
    details.details = det;
    return details;
  } else if (error instanceof Response) {
    const res: Response & { error: unknown } = error as Response & { error: unknown };
    const err = res.error as {
      type: string;
      title: string;
      status: number;
      detail: string;
      instance: string;
      efFlowId: string;
    };
    return new ErrorDetails({
      code: code,
      responseStatusCode: res.status,
      responseBody: err.detail,
      details: { additionalDetails: JSON.stringify(err) },
      efFlowId: err.efFlowId,
    });
  } else {
    const printed = `${error}`;
    const converted = JSON.stringify(error);
    return new ErrorDetails({
      code: code,
      details: { additionalDetails: printed.length > converted.length ? printed : converted },
      efFlowId: error.efFlowId,
    });
  }
}

/**
 * Handles the response from fetchRetry, parses JSON or throws an error
 * @param response The response object from fetchRetry
 * @param code Unique code of the error
 * @param details Additional details of the error to be used as a title/message and technical message to be displayed
 * @returns Promise<SomeType> if the response is successful, otherwise throws an error
 */

export function returnJsonOrThrowError<T>(response: ResponseWithFlowId): Promise<T> {
  if (response.ok) {
    return response.json() as Promise<T>;
  } else {
    const responseCode = response.status;
    return response.text().then((errorBodyAsText) => {
      throw new ErrorDetails({
        responseBody: errorBodyAsText,
        responseStatusCode: responseCode,
        efFlowId: response.efFlowId,
        url: response.url,
      });
    });
  }
}
